@font-face {
  font-family: 'Nunito';
  src: url('../../resources/fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Italic';
  src: url('../../resources/fonts/Nunito-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito SemiBold';
  src: url('../../resources/fonts/Nunito-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito SemiBoldItalic';
  src: url('../../resources/fonts/Nunito-SemiBoldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Bold';
  src: url('../../resources/fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito BoldItalic';
  src: url('../../resources/fonts/Nunito-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Light';
  src: url('../../resources/fonts/Nunito-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Italic';
  src: url('../../resources/fonts/Nunito-LightItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-xl {
  font-size: 120px;
}

.font-size-80 {
  font-size: 80px !important
}

.font-size-70 {
  font-size: 70px !important
}

.font-size-60 {
  font-size: 60px !important
}

.font-size-50 {
  font-size: 50px !important
}

.font-size-40 {
  font-size: 40px !important
}

.font-size-30 {
  font-size: 30px !important
}

.font-size-26 {
  font-size: 26px !important
}

.font-size-24 {
  font-size: 24px !important
}

.font-size-20 {
  font-size: 20px !important
}

.font-size-18 {
  font-size: 18px !important
}

.font-size-16 {
  font-size: 16px !important
}

.font-size-14 {
  font-size: 14px !important
}

.font-size-12 {
  font-size: 12px !important
}

.font-size-10 {
  font-size: 10px !important
}

.font-size-0 {
  font-size: 0 !important
}


.text-decoration-underline {
  text-decoration: underline;
}