@mixin respond-to($media) {
    @if $media == x-small-phone {
        @media only screen and (max-width: $x-small - 1) {
            @content;
        }
    } @else if $media == small-phone {
        @media only screen and (min-width: $x-small) {
            @content;
        }
    } @else if $media == phone {
        @media only screen and (min-width: $small) {
            @content;
        }
    } @else if $media == phablet {
        @media only screen and (min-width: $small + 1) and (max-width: $medium - 1) {
            @content;
        }
    } @else if $media == tablet {
        @media only screen and (min-width: $medium) and (max-width: $wide - 1) {
            @content;
        }
    } @else if $media == desktop {
        @media only screen and (min-width: $wide) {
            @content;
        }
    } @else if $media == wide-desktop {
        @media only screen and (min-width: $x-wide) {
            @content;
        }
    } @else if $media == x-wide-desktop {
        @media only screen and (min-width: $xx-wide) {
            @content;
        }
    } @else if $media == xx-wide-desktop {
        @media only screen and (min-width: $xxx-wide) {
            @content;
        }
    }
}

@mixin respond-to-height($media) {
    @if $media == small-phone {
        @media only screen and (max-height: $h-x-small) {
            @content;
        }
    } @else if $media == phone {
        @media only screen and (max-height: $h-small) {
            @content;
        }
    } @else if $media == phablet {
        @media only screen and (min-height: $h-small + 1) and (max-height: $h-medium - 1) {
            @content;
        }
    } @else if $media == tablet {
        @media only screen and (min-height: $h-medium) and (max-height: $h-wide - 1) {
            @content;
        }
    } @else if $media == desktop {
        @media only screen and (min-height: $h-wide) {
            @content;
        }
    } @else if $media == wide-desktop {
        @media only screen and (min-height: $h-x-wide) {
            @content;
        }
    } @else if $media == x-wide-desktop {
        @media only screen and (min-height: $h-xx-wide) {
            @content;
        }
    }
}

@mixin gradient($from, $to) {
    /* Firefox 3.6+ */
    background-image: -moz-linear-gradient($from, $to);

    /* Safari 4+, Chrome 1+ */
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));

    /* Safari 5.1+, Chrome 10+ */
    background-image: -webkit-linear-gradient($from, $to);

    /* Opera 11.10+ */
    background-image: -o-linear-gradient($from, $to);
}

@mixin placeholder() {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
    &::placeholder {
        @content
    }
}

@mixin border-image($attributes) {
    -moz-border-image: $attributes;
    -webkit-border-image: $attributes;
    -o-border-image: $attributes;
    border-image: $attributes;
}

@mixin box-shadow($attributes) {
    -o-box-shadow: $attributes;
    -ms-box-shadow: $attributes;
    -webkit-box-shadow: $attributes;
    -moz-box-shadow: $attributes;
    box-shadow: $attributes;
}

@mixin filter($attributes) {
    -webkit-filter: $attributes;
    filter: $attributes;
}
