/* Width */
.width-300px {
  width: 300px
}

.width-200px {
  width: 200px
}

.width-100 {
  width: 100%;
}

.width-90 {
  width: 90%;
}

.width-80 {
  width: 80%;
}

.width-70 {
  width: 70%;
}

.width-60 {
  width: 60%;
}

.width-40 {
  width: 40%;
}

.width-30 {
  width: 30%;
}

.vw-30 {
  width: 30vw;
}

.vw-25 {
  width: 25vw;
}

.vw-20 {
  width: 20vw;
}

.min-vw-20 {
  min-width: 20vw;
}

.min-vw-30 {
  min-width: 30vw;
}

.min-vw-40 {
  min-width: 40vw;
}

/* Height */
.height-200px {
  height: 200px;
}

.height-100px {
  height: 100px;
}

.height-50px {
  height: 50px;
}

.height-50 {
  height: 50%;
}

.height-40 {
  height: 40%;
}

.height-30 {
  height: 30%;
}

.height-25 {
  height: 25%;
}

.height-20 {
  height: 20%;
}

.height-15 {
  height: 15%;
}

.height-10 {
  height: 10%;
}

.vh-100 {
  height: 100vh;
}

.vh-70 {
  height: 70vh;
}

.vh-60 {
  height: 60vh;
}

.vh-50 {
  height: 50vh;
}

.vh-40 {
  height: 40vh;
}

.vh-30 {
  height: 30vh;
}

.vh-20 {
  height: 20vh;
}

.min-vh-70 {
  min-height: 70vh;
}

.min-vh-60 {
  min-height: 60vh;
}

.min-vh-50 {
  min-height: 50vh;
}

.min-vh-40 {
  min-height: 40vh;
}

.min-vh-30 {
  min-height: 30vh;
}

.min-vh-20 {
  min-height: 20vh;
}


/* Padding */
.padding-x-small {
  padding: 1em;
}

.padding-top-x-small {
  padding-top: 1em;
}

.padding-bottom-x2-large {
  padding-bottom: 7em;
}

.padding-bottom-x-large {
  padding-bottom: 6em;
}

.padding-bottom-large {
  padding-bottom: 5em;
}

.padding-bottom-x-medium {
  padding-bottom: 4em;
}

.padding-bottom-medium {
  padding-bottom: 3em;
}

.padding-bottom-small {
  padding-bottom: 2em;
}

.padding-bottom-x-small {
  padding-bottom: 1em;
}

.padding-bottom-x2-small {
  padding-bottom: .5em;
}

.padding-bottom-x3-small {
  padding-bottom: .25em;
}

/* Margin */
.margin-left-x-large {
  margin-left: 5em;
}

.margin-left-large {
  margin-left: 4em;
}

.margin-left-medium {
  margin-left: 3em;
}

.margin-left-small {
  margin-left: 2em;
}

.margin-left-x-small {
  margin-left: 1em;
}

.margin-left-x2-small {
  margin-left: .5em;
}

.margin-left-x3-small {
  margin-left: .25em;
}

.margin-right-x-small {
  margin-right: 1em;
}

.margin-right-x2-small {
  margin-right: .5em;
}

.margin-bottom-x2-large {
  margin-bottom: 7em;
}

.margin-bottom-x-large {
  margin-bottom: 6em;
}

.margin-bottom-large {
  margin-bottom: 5em;
}

.margin-bottom-x-medium {
  margin-bottom: 4em;
}

.margin-bottom-medium {
  margin-bottom: 3em;
}

.margin-bottom-small {
  margin-bottom: 2em;
}

.margin-bottom-x-small {
  margin-bottom: 1em;
}

.margin-bottom-x2-small {
  margin-bottom: .5em;
}

.margin-bottom-x3-small {
  margin-bottom: .25em;
}

.margin-top-x3-large {
  margin-top: 7em;
}

.margin-top-x2-large {
  margin-top: 6em;
}

.margin-top-x-large {
  margin-top: 5em;
}

.margin-top-large {
  margin-top: 4em;
}

.margin-top-medium {
  margin-top: 3em;
}

.margin-top-small {
  margin-top: 2em;
}

.margin-top-x-small {
  margin-top: 1em;
}

.margin-top-x2-small {
  margin-top: .5em;
}

.margin-top-x3-small {
  margin-top: .25em;
}

.margin-negative-right-x-small {
  margin-right: -1em;
}

