$icon-color-default: #087bbe;
$icon-color-grey: #8dc1e1;

.rating-icon {
  $size: 22px;

  display: inline-block;
  width: $size;
  height: $size;
  border-radius: $size / 2;
  vertical-align: middle;
  position: relative;

  & > div {
    position: absolute;
    border-radius: 50%;

    &:nth-of-type(1) {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:nth-of-type(2) {
      top: 10%;
      left: 10%;
      width: 80%;
      height: 80%;
    }

    &:nth-of-type(3) {
      top: 20%;
      left: 20%;
      width: 60%;
      height: 60%;
    }

    &:nth-of-type(4) {
      top: 30%;
      left: 30%;
      width: 40%;
      height: 40%;
    }

    &:nth-of-type(5) {
      top: 40%;
      left: 40%;
      width: 20%;
      height: 20%;
    }
  }

  &--perfect#{&} {
    & > div {
      &:nth-of-type(1) {
        background-color: $icon-color-grey;
      }

      &:nth-of-type(2) {
        background-color: $icon-color-default;
      }

      &:nth-of-type(3) {
        background-color: $icon-color-grey;
      }

      &:nth-of-type(4) {
        background-color: $icon-color-default;
      }

      &:nth-of-type(5) {
        background-color: white;
      }
    }
  }

  &--hints#{&} {
    & > div {
      &:nth-of-type(1) {
        background-color: $icon-color-grey;
      }

      &:nth-of-type(2) {
        background-color: $icon-color-default;
      }

      &:nth-of-type(3) {
        background-color: white;
      }

      &:nth-of-type(4) {
        background-color: $icon-color-default;
      }

      &:nth-of-type(5) {
        background-color: $icon-color-grey;
      }
    }
  }

  &--failed#{&} {
    & > div {
      &:nth-of-type(1) {
        background-color: white;
      }

      &:nth-of-type(2) {
        background-color: $icon-color-default;
      }

      &:nth-of-type(3) {
        background-color: $icon-color-grey;
      }

      &:nth-of-type(4) {
        background-color: $icon-color-default;
      }

      &:nth-of-type(5) {
        background-color: $icon-color-grey;
      }
    }
  }
}

.awards-timeline {
  .MuiSlider-thumb {
    width: 30px;
    height: 15px;
    border-radius: 15px 15px 0 0;
    margin-left: -15px;
    margin-top: -13px;
    z-index: 1;

    &:before {
      top: 4px;
      left: 10px;
      right: -10px;
      bottom: -2px;
      content: "";
      position: absolute;
      border-radius: 50%;
      background: cyan;
      width: 6px;
      height: 6px;
    }
  }

  .MuiSlider-valueLabel {
    margin-left: 15px;
  }

  .MuiSlider-track {
    height: 30px;
    margin-top: -28px;
    border-bottom: 2px solid white;
    text-align: center;
    z-index: 1;
    line-height: 30px;
    background-color: transparent;

    &:before {
      display: block;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      width: 100%;
      height: 13px;
      background: rgb(29, 226, 198);
      background: linear-gradient(90deg, rgba(29, 226, 198, 1) 0%, rgba(8, 123, 190, 0.95) 50%, rgba(29, 226, 198, 1) 100%);
      z-index: -1;
    }
  }
}

.awards-section-header {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  height: 275px;

  &.awards-section-header--medals {
    background-image: url('../../resources/img/ui/awards/medal_header.png');
  }

  &.awards-section-header--achievements {
    background-image: url('../../resources/img/ui/awards/achievements.png');
  }

  &.awards-section-header--privacy {
    background-image: url('../../resources/img/ui/profile/privacy_lock.png');
  }
}

.medal-container {
  position: relative;
  text-align: left;

  & > div {
    background-color: rgba(255, 255, 255, 0.22);
    background-repeat: no-repeat;
  }

  &--large {
    & > div {
      background-size: auto 105px;
      background-position: 40px center;
      border-radius: 23px;
      height: 130px;
      padding-left: 140px;
    }

    span {
      font-size: 100px;

      @include respond-to(x-small-phone) {
        font-size: 30px;
      }
      @include respond-to(small-phone) {
        font-size: 40px;
      }
      @include respond-to(phone) {
        font-size: 40px;
      }
      @include respond-to(phablet) {
        font-size: 60px;
      }
      @include respond-to(tablet) {
        font-size: 80px;
      }
      @include respond-to(desktop) {
        font-size: 100px;
      }
      @include respond-to(wide-desktop) {
        font-size: 100px;
      }
      @include respond-to(x-wide-desktop) {
        font-size: 100px;
      }
    }
  }

  &--small {
    & > div {
      background-size: auto 25px;
      background-position: 10px center;
      border-radius: 16px;
      height: 35px;
      padding: 10px 18px 10px 45px;;
    }

    span {
      font-size: 20px;
    }
  }

  &--bronze {
    background-image: url('../../resources/img/ui/awards/bronze_medal.png');
  }

  &--gold {
    background-image: url('../../resources/img/ui/awards/gold_medal.png');
  }

  &--silver {
    background-image: url('../../resources/img/ui/awards/silver_medal.png');
  }
}

.medals-total-count {
  @include respond-to(desktop) {
    margin-top: -55px;
  }
}

.hyphen-around {
  position: relative;

  &:before, &:after {
    background: white;
    background: linear-gradient(90deg, white 0%, $color-blue 70%, $color-blue 100%);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    top: 50%;
    width: 20px;
  }

  &:before {
    left: -25px;
    transform: rotateY(180deg);
  }

  &:after {
    right: -25px;
  }
}

// TODO: When awards page if done, check if merging these elements makes sense
//  (they're the same for now but they might not be when the awards page is done)
.award-list__category {
  border: 1px solid white;
  position: relative;
  height: 50px;
  width: 50px;

  img {
    position: absolute;
    margin: auto;
    max-height: 35px;
    max-width: 35px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.achievements-list {
  .card {
    .card-header {
      max-width: 96px;
    }

    .card-block {
      max-width: 600px;
    }
  }
}