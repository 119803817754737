@import '../_partials/colors';

.button-sp {
    background-color: $color-blue;
    border: none;
    padding: 7px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;

    &.button-sp-red {
        background-color: $color-sp-warning;
    }

    &:hover {
        background-color: $color-sp-light;
        color: inherit;

        &.button-sp-red {
            background-color: $color-sp-warning-light;
        }
    }
}

//


.button-no-text-decoration, .button-no-text-decoration>a{
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.collapse-btn {
    cursor: pointer;
}

.error {
    color: $color-alert;
    font-style: italic;
}
