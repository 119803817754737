.background-sp {
  background-color: $color-blue;
}

.background-white {
  background-color: white;
}

.font-sp {
  color: $color-blue;
}

.font-black {
  color: black;
}

.font-dim-grey {
  color: dimgrey;
}

.font-grey {
  color: grey;
}

.font-white {
  color: white;
}

.font-sp-topic {
  color: $color-sp-topic;
}

.font-sp-science {
  color: $color-sp-science;
}
