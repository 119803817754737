@import '_partials/variables';
@import '_partials/utilities';
@import '_partials/colors';
@import '_partials/typography';
@import '_partials/borders';
@import '_partials/layout';
@import '_partials/forms';
@import '_partials/input';
@import '_partials/images';
@import '_partials/navigation';
@import '_partials/awards';

/* Global */
* {
  font-family: 'Nunito', sans-serif;
}

body, html, #root, .app-container {
  height: 100%;
}

body, html, #root, .app-container, .background-sp-full {
  min-height: 100%;
}

/* COMMON ELEMENTS */
.cursor-pointer {
  cursor: pointer;
}

.select-none {
  user-select: none;
}

.center-image {
  margin: auto;
  display: block;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.fade-container {
  -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  max-height: 133px;
  overflow-y: scroll;

  li:last-child {
    margin-bottom: 50px;
  }
}

.alert-container {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 99999;
}

.sp-landing-container {
  @include respond-to(small-phone) {
    margin-top: 2rem;
  }
  @include respond-to(phone) {
    margin-top: 2rem;
  }
  @include respond-to(phablet) {
    margin-top: 2rem;
  }
  @include respond-to(tablet) {
    margin-top: 4rem;
  }
  @include respond-to(desktop) {
    margin-top: 6rem;
  }
  @include respond-to(wide-desktop) {
    margin-top: 6rem;
  }
  @include respond-to(x-wide-desktop) {
    margin-top: 6rem;
  }
}

.background-sp-landing {
  background-attachment: fixed;
  background-image: url('../resources/img/landing/background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-sp-full {
  background-attachment: fixed;
  background-image: url('../resources/img/landing/background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-slider {
  background-attachment: fixed;
  background-color: #017abf;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  min-height: 100%;
  padding-bottom: 125px;
}

.background-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.sp-school-logo {
  background-image: url('../resources/img/landing/bubble.png');
  background-size: contain;
  background-repeat: no-repeat;
}

/* ASSIGNMENT PAGE */
.assignment-item__title {
  color: $color-blue;
  font-size: 24px;
}

.assignment-item__question-category {
  color: $color-darkgreen;
  font-size: 17px;
}

.assignment-item__questions-status {
  color: $color-grey;
  font-size: 17px;
}

.assignment-item__question_body {
  color: $color-grey;
  font-size: 24px;
  line-height: 1;
}

.assignment-item__questions-status__complete-questions {
  color: $color-green;
}

.assignment-item__questions-status__user {
  color: $color-blue;
}

.assignment-item__deadline-label {
  color: $color-blue;
  font-size: 19px;
}

.assignment-item__deadline-content {
  color: $color-orange;
  font-size: 32px;
}

.assignment-item__question-confirm-button {
  background-color: $color-darkgreen;
  color: white; // TODO
  font-size: 24px;
  text-align: center;
  padding: 0.5em;
}

.assignment-item__question__status {
  border-style: solid;
  border-width: 60px 60px 0 60px;
  border-color: rgba(255, 255, 255, 0.3) transparent;
  color: white;
  font-size: 19px;

  & > div {
    margin-top: -55px;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.question-selector {
  .question-selector--title {
    font-size: 19px;
  }

  .question-selector-item {
    text-align: center;

    & > .btn {
      background-color: white;
      border-radius: 50%;
      color: $color-blue;
      font-size: 24px;
      height: 40px;
      line-height: 32px;
      margin-bottom: 40px;
      opacity: 0.5;
      width: 40px;
      vertical-align: middle;
    }

    &.active {
      & > .btn {
        border: 1px solid $color-green;
        height: 57px;
        line-height: 45px;
        opacity: 1;
        width: 57px;
      }
    }

    &.is-answered {
      & > .btn {
        background-color: $color-lightgreen;
      }
    }

    .btn-link:hover {
      text-decoration: none;
    }
  }
}

.question-item-body {
  border: 2px solid $color-darkgreen;
  color: white;
  font-size: 17px;

  & > .row {
    margin-top: -65px;
  }
}

.question-item-type {
  border-color: transparent;
  border-top-color: $color-darkgreen;
  border-style: solid;
  border-width: 40px 0 0 40px;

  &.type-success {
    border-top-color: $color-green;
  }

  &.type-danger {
    border-top-color: $color-sp-warning;
  }

  & > div {
    position: relative;
    top: -45px;
  }
}

.question-item-hint {
  border-color: transparent;
  border-top-color: $color-blue;
  border-style: solid;
  border-width: 40px 40px 0 0;

  & > div {
    position: relative;
    top: -45px;
  }
}

.question-flag {
  color: darkred;

  &:hover {
    color: red;
  }
}

.textarea-counter {
  background-color: dimgrey;
  border-radius: .25em;
  color: #fff;
  display: inline;
  font-size: 75%;
  font-weight: normal;
  line-height: 1;
  margin-top: 10px;
  padding: .2em .6em .3em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.answer-item-body {
  cursor: pointer;
  background-color: white;
  border: 3px solid transparent;

  &--correct#{&}, &--wrong#{&} {
    cursor: default;

    &, .assignment-item__answer_body {
      color: white;
    }
  }

  &--correct#{&} {
    background-color: $color-green;
  }

  &--wrong#{&} {
    background-color: $color-sp-warning;
  }

  &.active {
    border-color: $color-darkgreen;

    &:before {
      content: '';
      height: 0;
      width: 0;
      border-width: 10px;
      border-style: solid;
      border-color: $color-darkgreen transparent transparent $color-darkgreen;
      position: absolute;
    }
  }

  .assignment-item__answer_body {
    color: $color-blue;
    font-size: 19px;
    min-height: 49px; // TODO: fit images better
    //height: 37px;
  }
}

.user-answer-indicator {
  height: 49px;
  margin-left: 4px;
  position: relative;
  width: 49px;

  img {
    position: absolute;
    height: 49px;
    width: 49px;

    & ~ div {
      position: absolute;
      background-color: rgba(0, 137, 123, 0.7);
      height: 49px;
      padding-top: 12px;
      text-align: center;
      width: 49px;
    }
  }
}


/* Escape Lines */
.assignment-item__section-header {
  color: #ffffff;
  font-family: 'Nunito Light', Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.assignment-item__middle-line {
  border: 1px solid white;
  margin-top: 17px;
}

.wider-collapse-button {
  svg {
    transform: scaleX(1.5);
  }
}

.questions-list-item {
  & > div {
    margin-bottom: 25px;
  }
}

.assignment-item__section__content {
  color: $color-grey;
  font-family: 'Nunito Light', Arial, Helvetica, sans-serif;
  font-size: 19px;
  line-height: 1.1;
}

.question-comment_avatar {
  max-height: 72px;
  max-width: 72px;
}

.question-comment_username {
  color: $color-blue;
  font-size: 19px;
  line-height: 1.4;
}

.question-comment_date {
  color: $color-grey;
  font-size: 15px;
  float: right;
}

.comments-loader {
  color: white;
  font-family: 'Nunito Light', Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.question-item__comment_body {
  form {
    &, div, input {
      height: 100% !important;
    }
  }

  .question-comment_avatar {
    border-width: 2px !important;
  }
}

.notifications {

}

.info-panel {
  bottom: 0;
}

.info-panel-picture {
  max-width: 133px;
  max-height: 133px;
}

a.info-slider_more-button {
  color: $color-blue;
  font-size: 16px;
  line-height: 1;
}

.info-slider_title {
  color: $color-blue;
  font-size: 19px;
  line-height: 1;
}

/* Avatar Creator/Selector */
.avatar-selector {
  .button-row-left {
    margin-right: -2.5rem;
    @include respond-to(x-small-phone) {
      margin-right: 0;
    }
    @include respond-to(small-phone) {
      margin-right: -1rem;
    }
    @include respond-to(phone) {
      margin-right: -3rem;
    }
    @include respond-to(phablet) {
      margin-right: -5rem;
    }
    @include respond-to(tablet) {
      margin-right: -1rem;
    }
    @include respond-to(desktop) {
      margin-right: -2.5rem;
    }
    @include respond-to(wide-desktop) {
      margin-right: -2.5rem;
    }
    @include respond-to(x-wide-desktop) {
      margin-right: -2.5rem;
    }

    .row > .col {
      margin-bottom: 1.5rem;
      @include respond-to(small-phone) {
        margin-bottom: 1rem;
      }
      @include respond-to(phone) {
        margin-bottom: 1rem;
      }
      @include respond-to(phablet) {
        margin-bottom: 1rem;
      }
      @include respond-to(tablet) {
        margin-bottom: 2rem;
      }
      @include respond-to(desktop) {
        margin-bottom: 2rem;
      }
      @include respond-to(wide-desktop) {
        margin-bottom: 2rem;
      }
      @include respond-to(x-wide-desktop) {
        margin-bottom: 2rem;
      }
    }
  }

  .button-row-right {
    margin-left: inherit;
    @include respond-to(small-phone) {
      margin-left: -2rem;
    }
    @include respond-to(phone) {
      margin-left: -2rem;
    }
    @include respond-to(phablet) {
      margin-left: -2rem;
    }
    @include respond-to(tablet) {
      margin-left: -2rem;
    }
    @include respond-to(desktop) {
      margin-left: -2rem;
    }
    @include respond-to(wide-desktop) {
      margin-left: inherit;
    }
    @include respond-to(x-wide-desktop) {
      margin-left: inherit;
    }

    .row > .col {
      margin-bottom: 1.5rem;
      @include respond-to(small-phone) {
        margin-bottom: 1rem;
      }
      @include respond-to(phone) {
        margin-bottom: 1rem;
      }
      @include respond-to(phablet) {
        margin-bottom: 1rem;
      }
      @include respond-to(tablet) {
        margin-bottom: 2rem;
      }
      @include respond-to(desktop) {
        margin-bottom: 2rem;
      }
      @include respond-to(wide-desktop) {
        margin-bottom: 2rem;
      }
      @include respond-to(x-wide-desktop) {
        margin-bottom: 2rem;
      }
    }
  }
}

/* Awards page */
// This class is also used in the explore section, consider duplicating / generalizing?
.topic-list__category {
  border: 1px solid white;
  position: relative;
  height: 50px;
  width: 50px;

  img {
    position: absolute;
    margin: auto;
    max-height: 35px;
    max-width: 35px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.topic-list__test-count {
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

.awards-table {
  tr {
    height: 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  span {
    line-height: 50px;
    vertical-align: middle;
  }
}

.sp-icon-button {
  background-color: $color-blue;
  border: 2px solid white;
  padding: 15px;
  width: fit-content;
}

.answers {
  .alert-dismissible {
    .close {
      color: white;
      padding-top: .35rem;
      z-index: 1;
    }
  }
}

.divider {
  border-top: white 1px solid;
}

.truncate-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include respond-to(x-small-phone) {
    width: 200px;
  }

  @include respond-to(small-phone) {
    width: 250px;
  }

  @include respond-to(phone) {
    width: 300px;
  }

  @include respond-to(phablet) {
    width: 350px;
  }

  @include respond-to(tablet) {
    width: 450px;
  }

  @include respond-to(desktop) {
    width: 600px;
  }

  @include respond-to(wide-desktop) {
    width: 700px;
  }
}