.nav-arrow {
    display: flex;
    height: 4em;
    width: 4em;

    img {
        max-width: 30px;
        max-height: 32px;
    }
}

.nav-sp {
    align-items: center;
    display: flex;
    height: 5em;
    justify-content: center;
    min-width: 10em;
    text-align: center;

    @include respond-to(small-phone) {
        height: auto;
    }
    @include respond-to(phone) {
        height: auto;
    }
    @include respond-to(phablet) {
        height: auto;
    }
    @include respond-to(tablet) {
        height: auto;
    }
    @include respond-to(desktop) {
        height: 5em;
    }
    @include respond-to(wide-desktop) {
        height: 5em;
    }
    @include respond-to(x-wide-desktop) {
        height: 5em;
    }

    &.active {
        color: white !important;
        background-color: $color-blue;
    }
}
