.border-none {
  border: none;
}

.border-small {
  border: 1px solid grey;
}

.border-sp-small {
  border: 1px solid $color-blue;
}

.border-sp-medium {
  border: 3px solid $color-blue;
}

.border-sp {
  border: 15px solid $color-blue;
}

hr.hr-sp {
  border-top: 1px solid $color-blue;
}

.shadow-sp {
  -webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.75);
}

.border-radius-none {
  border-radius: 0 !important;
}
