/* Coefs */
$phone-coef: 0.4;
$phablet-coef: 0.58;
$tablet-coef: 0.85;
$wide-desktop-coef: 1.2;

/* Sizes */
/* Common widths */
$x-small: 500px;
$small: 576px;
$medium: 768px;
$wide: 992px;
$x-wide: 1200px;
$xx-wide: 1675px;
$xxx-wide: 2000px;

/* Common heights */
$h-x-small: 480px;
$h-small: 568px;
$h-medium: 600px;
$h-wide: 700px;
$h-x-wide: 970px;
$h-xx-wide: 1300px;

/* Colors */
$color-blue: #0091ea;
$color-sp-light: #00abff;
$color-sp-warning: #dc3545;
$color-sp-warning-light: #ff596a;
$color-sp-topic: #f9a825;
$color-sp-science: #ea2d00;
$color-lightgreen: #33d274;
$color-green: #00c853;
$color-grey: #78909c;
$color-darkgrey: #546e7a;
$color-orange: #f9a825;
$color-darkgreen: #00897b;
$color-alert: #f50057;
$color-purple: #c51162;
$color-carrot: #dd2c00;
