button.btn-no-style {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.placeholder-white {
    &::placeholder {
        color: white;
    }

    &::-ms-input-placeholder {
        color: white;
    }

    &::-ms-input-placeholder {
        color: white;
    }
}

.placeholder-italic {
    &::placeholder {
        font-style: italic;
    }

    &::-ms-input-placeholder {
        font-style: italic;
    }

    &::-ms-input-placeholder {
        font-style: italic;
    }
}

.form-transparent {
    border: none;
    background-color: transparent;
    -webkit-appearance: none;

    &:focus {
        box-shadow: none;
    }
}